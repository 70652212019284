import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TendenciaService {

  private url = environment.catalogEndpoint + '/tendencia';
  constructor(
    private http: HttpClient,
    private auth: AuthService) {

  }

  async getProducts() {
    const token = await this.auth.getAuthToken();
    return await this.http.get(this.url + '/products', { headers: { authorization: 'Bearer ' + token } }).toPromise();
  }

  async getValue(ddd: string) {
    const token = await this.auth.getAuthToken();
    return await this.http.post(this.url + '/ddd', { ddd }, { headers: { authorization: 'Bearer ' + token } }).toPromise();
  }

  async checkout(user, endereco, product) {
    const token = await this.auth.getAuthToken();
    return await this.http.post(this.url + '/checkout', {
      user,
      endereco,
      produto: product
    }, { headers: { authorization: 'Bearer ' + token } }).toPromise();
  }

}
