import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../interfaces/user';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GifttyService {

  private url = environment.catalogEndpoint + '/giftty';

  constructor(
    private http: HttpClient,
    private auth: AuthService) { }


  public async getProducts(body) {
    const token = await this.auth.getAuthToken();
    return await this.http.post(this.url + '/productsCode', body, { headers: { authorization: 'Bearer ' + token } }).toPromise();
  }

  public async getProductByQuery(query) {
    const token = await this.auth.getAuthToken();
    return await this.http.post(this.url + '/products',
      query, { headers: { authorization: 'Bearer ' + token } }).toPromise();
  }

  public async checkout(user: User, endereco: any, product: any) {
    console.log(user);
    console.log(endereco);
    console.log(product);

    const body = {
      endereco,
      user,
      produto: product
    };
    const token = await this.auth.getAuthToken();
    return await this.http.post(this.url + '/order', body, { headers: { authorization: 'Bearer ' + token } }).toPromise();
  }

}
